@if (balances && balances.balance && balances.balance !== 0) {
  <div
    class="card card-compact mb-4"
    [class.bg-success]="balances.balance > 0"
    [class.bg-error]="balances.balance < 0"
  >
    <div class="card-body flex flex-row justify-between items-center">
      <div class="card-title text-white">Total Balance</div>
      <h3 class="text-white">
        {{ balances.balance | currency: 'USD' : 'symbol' : '1.2-2' }}
      </h3>
    </div>
  </div>
} @else {
  <div class="card card-compact mb-4">
    <div class="card-body flex flex-row justify-between items-center">
      <div class="card-title">Total Balance</div>
      <h3>
        {{ balances?.balance ?? 0 | currency: 'USD' : 'symbol' : '1.2-2' }}
      </h3>
    </div>
  </div>
}
<div class="grid md:grid-cols-2 gap-4">
  @if (balances && balances.byAccount) {
    @for (
      account of balances.byAccount | keyvalue | sortAlphabeticallyKeyValue;
      track account.key
    ) {
      <app-account-balance-detail
        [account]="account.value"
        [accountId]="account.key"
        [balance]="account.value?.balance"
        [userId]="userId"
      ></app-account-balance-detail>
    }
  }
</div>
