import { CurrencyPipe, KeyValuePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AccountBalances, CreditAccount } from 'src/app/shared/models';
import { SortAlphabeticallyKeyValuePipe } from 'src/app/shared/services/account/pipes/sort-alphabetically.pipe';
import { AccountBalanceDetailComponent } from '../account-balance-detail/account-balance-detail.component';

@Component({
  selector: 'app-account-balances-detail',
  standalone: true,
  imports: [
    AccountBalanceDetailComponent,
    CurrencyPipe,
    KeyValuePipe,
    SortAlphabeticallyKeyValuePipe,
  ],
  templateUrl: './account-balances-detail.component.html',
  styleUrl: './account-balances-detail.component.scss',
})
export class AccountBalancesDetailComponent {
  @Input() accounts?: CreditAccount[];
  @Input() balances?: AccountBalances;
  @Input() userId?: string;
}
