import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {
  CARRY_FORWARD_ACCOUNT_ID,
  getDefaultCarryForwardAccount,
} from 'src/app/shared/data/constants';

@Pipe({
  name: 'sortAlphabetically',
  standalone: true,
})
export class SortAlphabeticallyPipe implements PipeTransform {
  transform<
    T extends { name?: string; id?: string },
    K extends KeyValue<string, object>,
  >(value: T[] | K[]): any[] {
    const v = value as T[];
    if (!v.find((x) => x.id === CARRY_FORWARD_ACCOUNT_ID)) {
      v.push(getDefaultCarryForwardAccount() as T);
    }

    return v.sort((a, b) => {
      if (a.id === CARRY_FORWARD_ACCOUNT_ID) return -1;
      if (b.id === CARRY_FORWARD_ACCOUNT_ID) return 1;
      return (a.name ?? '').localeCompare(b.name ?? '');
    });
  }
}

@Pipe({
  name: 'sortAlphabeticallyKeyValue',
  standalone: true,
})
export class SortAlphabeticallyKeyValuePipe implements PipeTransform {
  transform<K extends KeyValue<string, object>>(value: K[]): any[] {
    const v = value as K[];
    if (!v.find((x) => x.key === CARRY_FORWARD_ACCOUNT_ID)) {
      console.error('Adding Carry Forward Account');
      v.push({
        key: CARRY_FORWARD_ACCOUNT_ID,
        value: getDefaultCarryForwardAccount(),
      } as K);
    }

    return v.sort((a, b) => {
      if (a.key === CARRY_FORWARD_ACCOUNT_ID) return -1;
      if (b.key === CARRY_FORWARD_ACCOUNT_ID) return 1;

      return ((a.value as { name?: string })?.name ?? '').localeCompare(
        (b.value as { name?: string })?.name ?? '',
      );
    });
  }
}
