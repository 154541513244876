import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { CreditAccount } from 'src/app/shared/models';
import { ShowModalComponents } from 'src/app/shared/services/modal-container.service';
import { ModalContainerService } from 'src/app/shared/services/modal-container.service';
import { UserCardComponent } from '../../ui-components/user-card/user-card.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CARRY_FORWARD_ACCOUNT_ID } from 'src/app/shared/data/constants';

@Component({
  selector: 'app-account-balance-detail',
  standalone: true,
  imports: [AsyncPipe, CurrencyPipe, FaIconComponent, UserCardComponent],
  templateUrl: './account-balance-detail.component.html',
  styleUrl: './account-balance-detail.component.scss',
})
export class AccountBalanceDetailComponent {
  private authService = inject(AuthService);
  private modalContainerService = inject(ModalContainerService);

  faEye = faEye;

  @Input() account?: CreditAccount;
  @Input() accountId?: string;
  @Input() balance?: number;
  @Input() userId?: string;

  user$;
  CARRY_FORWARD_ACCOUNT_ID = CARRY_FORWARD_ACCOUNT_ID;

  constructor() {
    this.user$ = this.authService.user$;
  }

  showTransactions() {
    if (!this.account || (!this.account?.id && !this.accountId)) {
      console.error('No account id', this.account);
      return;
    }

    this.modalContainerService.open(ShowModalComponents.PAYOUT_OVERVIEW, {
      userId: this.userId ?? this.account?.owner?.id,
      // agencyFilterType: 'MANUAL_PAYOUTS',
      showAllCycles: true,
      accounts: [
        {
          ...this.account,
          title: this.account?.name,
          id: this.account?.id ?? this.accountId,
        },
      ],
    });
  }
}
