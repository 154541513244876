<div class="card card-compact">
  <div class="card-body">
    <div>
      <div class="card-title">
        @if ((account?.id ?? accountId) === CARRY_FORWARD_ACCOUNT_ID) {
          Balance Carry Forward
        } @else {
          Balance {{ account?.name ?? '' }}
        }
      </div>
      @if (user$ | async; as user) {
        @if (
          account &&
          account.owner &&
          account.owner.id &&
          account.owner.name &&
          account.owner.id !== user.user.uid
        ) {
          <app-user-card
            class="mb-4"
            type="card"
            [small]="true"
            [userId]="account.owner.id"
            [user]="account.owner"
          ></app-user-card>
        }
      }
    </div>

    <h3
      [class.text-error]="balance && balance < 0"
      [class.text-success]="balance && balance > 0"
    >
      {{ balance ?? 0 | currency }}
    </h3>

    <div class="card-actions justify-end">
      <button
        class="btn btn-outline btn-sm btn-ghost gap-2"
        (click)="showTransactions()"
      >
        <fa-icon [icon]="faEye"></fa-icon> Show Transactions
      </button>
    </div>
  </div>
</div>
